import ITMSimg1 from "../assets/img/DesignDiagram/ITMS/lower12.drawio.png";
import ITMSimg2 from "../assets/img/DesignDiagram/ITMS/lower22.drawio.png";
import ITMSimg3 from "../assets/img/DesignDiagram/ITMS/uper2.drawio.png";
import ITMSIcon1 from "../assets/img/Technologies/React-JS.jpeg";
import ITMSIcon2 from "../assets/img/Technologies/nodejs.png";
import ITMSIcon3 from "../assets/img/Technologies/signalr2.PNG";
import ITMSIcon4 from "../assets/img/Technologies/sqlserver2.png";

import POSimg1 from "../assets/img/DesignDiagram/POS/POS-Field-View.drawio.png";
import POSimg2 from "../assets/img/DesignDiagram/POS/POS-Dashboard-View.drawio.png";
import POSIcon1 from "../assets/img/Technologies/React-Native.jpeg";
import POSIcon2 from "../assets/img/Technologies/nodejs.png";
import POSIcon3 from "../assets/img/Technologies/React-JS.jpeg";

import SCANNDOimg1 from "../assets/img/DesignDiagram/SCANNDO/Menubuilder.drawio.png";
import SCANNDOimg2 from "../assets/img/DesignDiagram/SCANNDO/restaurantsystem.drawio.png";
import SCANNDOimg3 from "../assets/img/DesignDiagram/SCANNDO/Userinterfacesignalr.drawio.png";
import SCANNDOIcon1 from "../assets/img/Technologies/msDotNet.png";
import SCANNDOIcon2 from "../assets/img/Technologies/MVC.jpg";
import SCANNDOIcon3 from "../assets/img/Technologies/azurePNG.png";
import SCANNDOIcon4 from "../assets/img/Technologies/sqlCloud.png";

import EMSBimg1 from "../assets/img/DesignDiagram/EMSB/Picture1.png";
import EMSBimg2 from "../assets/img/DesignDiagram/EMSB/Picture2.png";
import EMSBimg3 from "../assets/img/DesignDiagram/EMSB/Picture3.jpg";

import RFIDimg1 from "../assets/img/DesignDiagram/RFID/rfidDiagram1.jpg";
import RFIDimg2 from "../assets/img/DesignDiagram/RFID/rfidDiagram2.jpg";

import ECHALLANimg1 from "../assets/img/DesignDiagram/EChallan/Picture1.png";

import ITMScasestudy from "../case_study/ITMS.pdf";
import POScasestudy from "../case_study/Smart-Parking-App.pdf";
import SCANNDOcasestudy from "../case_study/QR-code-ordering.pdf";

const projectData = [
    {
        id: 1,
        projectTitle: "ITMS",
        projectName: "Transport hubs for smart cities",
        text: "(Transport Application)",
        industry: "Transport, Smart Cities",
        platform: "Integrated System",
        caseStudyPdfLink: ITMScasestudy,
        demoVideoLink: "https://youtu.be/FHX_2SWNF7k?si=UkFhJunHgzokwWfB",
        introductionTitle: "What is ITMS?",
        introductionText:
            "ITMS stands for Integrated Transport Management System. It is designed to enhance the commuting experience in transport hubs of smart cities by leveraging technology. ITMS aims to attract more people to use mass transport systems rather than personal vehicles for their daily commuting, providing a seamless and efficient travel experience.",
        aboutClient:
            "Our client, a transportation business operator from the Africa region, manages a fleet of buses across the province. They aimed to improve the commuter experience by implementing key features such as QR-coded tickets, bus arrival/departure information displays, live bus location tracking, and streamlined boarding processes. The system was designed to:",
        clientPoints: [
            "Issue Tickets Quickly: Utilizing smart cards, eliminating the need for cash.",
            "Facilitate Chaos-Free Entry: Using QR-coded tickets and turnstiles.",
            "Display Bus Information: Showing arrival and departure times to reduce waiting periods.",
            "Track Bus Locations: Providing real-time updates on bus locations.",
            "Manage Smart Cards: Allowing easy replacement and balance transfer in case of loss or damage.",
        ],
        challangesProblems: [
            "Integration of Hardware and Software: Close integration required for GPS tracking systems, smart card readers, thermal printers, QR code scanners, turnstile gates, and software systems.",
            "Smart Card Management: Handling operations like card recharges, blocking lost/damaged cards, issuing new cards, and maintaining transaction history.",
        ],
        solutionHeadline: "Multiple systems were developed as part of the complete solution:",
        solution: [
            "Admin Portal: Configures buses, routes, ticket fares, and bus schedules.",
            "Ticket Issuance & Smart Card Portal: Generates tickets and manages smart card operations (recharging, blocking, issuing, balance transfers).",
            "Bus Arrival/Departure Display System: Shows information about bus timings and boarding status.",
            "Turnstile System: Equipped with QR code scanners to validate tickets and allow boarding.",
        ],
        diagrams: [
            ITMSimg1,
            ITMSimg2,
            ITMSimg3,
        ],
        technologies: [
            "Front End: Developed using React JS.",
            "Backend API: Developed using Node JS.",
            "Middleware: SignalR used for communication with smart card readers.",
            "Windows Service: Implemented to read QR codes, validate tickets, and operate turnstiles.",
            "Database: SQL Server used for storing configuration and ticket data.",
        ],
        technologyicons: [
            ITMSIcon1,
            ITMSIcon2,
            ITMSIcon3,
            ITMSIcon4
        ],
        services: [
            "Preparation of Software Requirement Specification (SRS): Detailed documentation of system requirements and specifications.",
            "Architecture Design: High-level and detailed architecture design for a robust solution.",
            "Hardware Integration and Testing: Ensuring seamless integration and functionality of various hardware components.",
            "Solution Development: Complete coding, rigorous testing, and maintenance of the solution.",
            "Deployment: Efficient deployment of systems across various terminals.",
        ],
        benefitsOf: 'Benefits of ITMS',
        benefitsPoints: [
            "Streamlined Commuting: Reduces chaos and improves efficiency in transport hubs.",
            "Enhanced Commuter Experience: Provides real-time information and easy access to tickets.",
            "Operational Efficiency: Reduces the need for manual processes and staff intervention.",
            "Increased Public Transport Usage: Encourages people to use mass transport systems over personal vehicles.",
        ],
        byTheline: 'By implementing ITMS, transport operators can significantly improve the efficiency and user experience of public transportation systems, making them more appealing and reliable for daily commuters.',
    },
    {
        id: 2,
        projectTitle: "POS",
        projectName: "Smart Parking Ticketing Solution",
        text: "(Revolutionizing Parking Management with Technology)",
        industry: "Smart Cities",
        platform: "Android OS based POS system",
        caseStudyPdfLink: POScasestudy,
        demoVideoLink: "https://youtu.be/djOpS_7rvL0?si=5JuKxzmCURCCSNSj",
        introductionTitle: "What is Smart Parking Ticketing solution?",
        introductionText:
            "The Smart Parking Ticketing solution aims to streamline the process of issuing parking lot tickets and efficiently handling traffic at exit gates. Our innovative system offers rapid ticket issuance through POS machines, automatic gate operations upon payment, and precise fare calculations based on the duration of parking. This results in overall operational efficiency and a seamless experience for vehicle owners. Additionally, digitizing the ticket issuance process helps operators plug revenue leakages.",
        aboutClient:
            "This solution is designed for high-traffic areas such as malls, multiplexes, theaters, stadiums, and exhibition centers, where efficient management of parking facilities is crucial. The primary clients are operators contracted to manage these parking facilities.",
        clientPoints: [
            "Admin web panel component: used to configure master data i.e. operators/users, vehicle types, fare structure vehicle type wise, etc. One very important use of admin panel is to view revenue reports with different filters i.e. operator wise, date range wise, vehicle type wise, etc. which gives very important insight/analytics to operators about the trends and where action is required",
            "POS device app component: App running on POS helps to print entry and exit tickets rapidly, calculate exact fare based on fare structure.",
        ],
        challangesProblems: [
            "Manual Operations: Current parking operations are largely manual, causing delays at exit gates, fare calculation errors, and revenue losses.",
            "Lack of Data: Operators lack access to data and reports needed to measure performance and make informed decisions.",
            "Revenue Leakages: Manual processes result in significant revenue leakages due to inefficiencies and human errors.",
        ],
        solutionHeadline: "We developed a comprehensive Smart Parking Ticketing solution to address these challenges, incorporating multiple systems for seamless operation:",
        solution: [
            "Admin Web Panel: Configuration - Allows configuration of master data such as operators, users, vehicle types, and fare structures. Reporting - Provides detailed revenue reports with filters (e.g., operator-wise, date range-wise, vehicle type-wise), offering valuable insights and analytics for operators.",
            "POS device App: Ticket Issuance - The app running on Android-based POS devices enables rapid printing of entry and exit tickets with QR codes. Fare Calculation - Calculates exact fare based on the configured fare structure. Offline Capability - Operates without requiring an internet connection, syncing data with the admin panel when connectivity is available.",
        ],
        diagrams: [
            "../assets/img/DesignDiagram/POS/POS-Dashboard-View.drawio.png",
            "../assets/img/DesignDiagram/POS/POS-Field-View.drawio.png",
        ],
        diagrams: [
            POSimg1,
            POSimg2,
        ],
        technologies: [
            "Admin Portal - Front End: Built with React JS. Back End: Developed using Node JS for the API system. Database: Utilizes SQL Server for storing configuration and ticket data.",
            "POS Device App - Development: Created using React Native for the Android operating system.",
        ],
        technologyicons: [
            POSIcon1,
            POSIcon2,
            POSIcon3,
        ],
        services: [
            "Software Requirement Specification (SRS) Preparation: Detailed documentation of system requirements and specifications.",
            "Architecture Designing: High-level and detailed architecture design to ensure robust and scalable solutions.",
            "Hardware Integration and Testing: Integration and testing of various hardware components with the software system.",
            "Coding, Testing, and Maintenance: Complete solution development including coding, rigorous testing, and ongoing maintenance.",
        ],
        benefitsOf: 'Benefits of Smart Parking Ticketing Solution',
        benefitsPoints: [
            "Operational Efficiency: Rapid ticket issuance and automated gate operations reduce delays and improve traffic flow.",
            "Accurate Fare Calculation: Eliminates errors in fare calculations, ensuring fair and precise billing.",
            "Data-Driven Insights: Detailed reports and analytics help operators make informed decisions and improve performance.",
            "Revenue Protection: Digital processes reduce revenue leakages and enhance financial accountability.",
            "Enhanced User Experience: Streamlined operations provide a smooth and pleasant experience for vehicle owners.",
        ],
        byTheline: 'By implementing an RFID-based patient tracking system, hospitals can significantly improve patient safety, operational efficiency, and overall management. This innovative solution not only addresses immediate challenges but also sets the foundation for a smarter, more responsive healthcare environment.',

    },
    {
        id: 3,
        projectTitle: "ScanNDo",
        projectName: "QR Code Based Food Ordering n Delivery System",
        text: "(QR Code Scan Application)",
        industry: "Horeca",
        platform: "Integrated System",
        caseStudyPdfLink: SCANNDOcasestudy,
        demoVideoLink: "https://youtu.be/X_XB5gzqiXg?si=XQ7jnKZov0NTTdjB",
        introductionTitle: "What is ScanNDo?",
        introductionText:
            "ScanNDo is a comprehensive solution designed to help restaurant business owners establish a robust online presence and provide touch-free ordering experiences. It enables complete online food ordering, menu browsing, order cart management, payment processing, and order status tracking through QR codes. This solution is ideal for small restaurant business owners looking to expand their business footprint online.",
        aboutClient:
            "",
        clientPoints: [
            "Customer Experience: Customers can scan a QR code with their phone to access a mobile-friendly menu, place orders, and make payments. They can also receive order status updates on their mobile devices.",
            "Restaurant Management: Restaurants receive notifications about new orders on their billing desk system. They can design their menus, offer promo codes, and manage the entire ordering process digitally.",
        ],
        challangesProblems: [
            "Rapid Online Transition: Business owners can bring their business online in just a few hours.",
            "Touch-Free Ordering: Ensures customer safety, especially during pandemic situations.",
            "Reduced Staffing Needs: The digital ordering process requires less staff.",
            "Customized Marketing: Businesses can run campaigns with discounts and offers through promo codes.",
            "Direct Payments: Restaurants receive full payments without commission cuts from third-party platforms.",
        ],
        solutionHeadline: "ScanNDo is a SaaS solution tailored for small restaurant business owners. It was developed to address the significant business losses during the pandemic by facilitating online ordering and delivery services.",
        solution: [
            "Touchless Experience: Replaces physical menus with QR codes. Customers scan the QR code, view the menu, and place orders via their mobile devices without needing to download any app.",
            "Quick Setup: Restaurants can get online in a matter of hours in a cost-effective manner.",
            "Order Management: The system notifies restaurants of new orders and provides tools for managing menu offerings and promotional campaigns.",
        ],
        diagrams: [
            SCANNDOimg1,
            SCANNDOimg2,
            SCANNDOimg3,
        ],
        technologies: [
            "Front End: Built with HTML/CSS3/Material Design specifications.",
            "Middleware developed using ASP.NET - MVC.",
            "Database: Azure SQL database used for backend data storage.",
            "Payment Integration: Stripe and Razorpay payment gateways.",
            "Deployment: All systems are deployed on the Microsoft Azure Platform, utilizing Azure services like App Services and SQL Azure.",
        ],
        technologyicons: [
            SCANNDOIcon1,
            SCANNDOIcon2,
            SCANNDOIcon3,
            SCANNDOIcon4
        ],
        services: [
            "Preparation of Software Requirement Specification (SRS): Detailed documentation of system requirements and specifications.",
            "Architecture Design: High-level and detailed architecture design for a robust solution.",
            "Hardware Integration and Testing: Ensuring seamless integration and functionality of various hardware components.",
            "Solution Development: Complete coding, rigorous testing, and maintenance of the solution.",
            "Deployment: Efficient deployment of systems across various terminals.",
        ],
        byTheline: "By implementing ScanNDo, restaurant owners can quickly adapt to changing market conditions, enhance customer safety, and streamline their operations, leading to increased efficiency and customer satisfaction.",
    },
    {
        id: 4,
        projectTitle: "EMSB",
        projectName: "Electricity Meter Spot Billing",
        text: "(Spot Billing Solution)",
        industry: "Smart Cities",
        platform: "POS Device, Thermal Printer",
        caseStudyPdfLink: "",
        demoVideoLink: "",
        introductionTitle: "What is Spot Billing?",
        introductionText:
            "Spot billing is an advanced utility billing system for power, water, and gas services where meter readings are taken on-site, and bills are generated and collected immediately. This system utilizes digital payment options, providing instant feedback to consumers. The primary objectives of spot billing are to enhance billing accuracy, reduce delays, and improve customer satisfaction. This case study explores the implementation and impact of spot billing in a mid-sized city, examining the processes involved, challenges faced, and outcomes achieved.",
        aboutClient:
            "Our clients include both government and private utility companies facing challenges such as billing errors, delayed billing, lack of transparency, inconvenient payment options, poor customer service, operational inefficiencies, and inaccurate data collection. By adopting electricity meter spot billing, these companies can improve accuracy, enhance customer satisfaction, increase operational efficiency, achieve cost savings, gain real-time data insights, better manage revenue, and empower customers.",
        clientPoints: [
            "Gujarat Gas",
            "GEB(Gujarat Electricity Board)",
            "MPMKVCL(Madhya Pradesh Madhya Kshetra Vidyut Vitaran Company Limited)",
            "Torrent Power",
            "Adani Gas etc.",
        ],
        challangesProblems: [
            "Delays in Billing and Payment Collection: Manual collection of meter readings and payments leads to inefficiencies.",
            "Errors in Meter Readings: Inaccurate readings result in billing disputes.",
            "High Operational Costs: Manual processes increase operational expenses.",
            "Consumer Dissatisfaction: Delayed and inaccurate bills lead to poor customer service.",
        ],
        solutionHeadline: "We developed multiple systems as part of the complete spot billing solution:",
        solution: [
            "Meter Reading: Meter readers visit households and use handheld devices to read smart meters. The device captures the meter reading and checks for any anomalies.",
            "Bill Generation: The handheld device generates an on-the-spot bill based on the latest meter reading. The bill includes consumption details, tariff rates, and the total amount due.",
            "Bill Delivery: Consumers receive a printed bill immediately. An electronic copy is sent to the consumer’s registered email.",
            "Payment Options: Consumers can pay the bill on the spot via mobile payment, Debit card , credit card, or cash. Alternatively, they can pay later through online portals, banks, or MEC offices.",
        ],
        diagrams: [
            EMSBimg1,
            EMSBimg2,
            EMSBimg3,
        ],
        technologies: [
            "Handheld Devices: Equipped with capabilities to read smart meters and generate on-site bills.",
            "Smart Meters: Installed to facilitate easy reading by handheld devices.",
            "Software Integration: Robust software system integrating meter readings, billing, and customer records.",
            "Wave Walk By AMR (Automatic Meter Reading) System: Uses 865-867 MHZ Wireless MBUS radio technology for fast, reliable meter readings without entering homes.",
            "Wave Meter Module: Fitted on meters, it counts pulses and transmits index readings at regular intervals.",
            "User-Friendly Mobile App: Allows utility providers to monitor meter functionality, such as tamper alerts and battery voltage.",
            "Wave Operating System: Cloud/server-based system with a user-friendly GUI and web-based online access for users.",
            "Data Availability: Provides customized reports and billing formats at predefined intervals.",
            "Payment Management System: Manages payments efficiently.",
            "Wave Pay As You Go Token (PAYGT) System: Enables pre-payment for utilities, ensuring usage is within prepaid limits.",
        ],
        technologyicons: [
            SCANNDOIcon1,
            SCANNDOIcon2,
            SCANNDOIcon3,
            SCANNDOIcon4
        ],
        services: [
            "Software Requirement Specification (SRS) Preparation: Detailed documentation of system requirements and specifications.",
            "Architecture Designing: High-level and detailed architecture design for robust and scalable solutions.",
            "Hardware Integration and Testing: Integration and testing of various hardware components with the software system.",
            "Coding, Testing, and Maintenance: Complete solution development, including coding, rigorous testing, and ongoing maintenance.",
            "Deployment: Deployment of systems across various terminals for efficient operation.",
        ],
        benefitsOf: 'Benefits of Spot Billing',
        benefitsPoints: [
            "Improved Accuracy: Reduces errors in meter readings and billing.",
            "Enhanced Customer Satisfaction: Provides immediate feedback and multiple payment options.",
            "Operational Efficiency: Streamlines processes, reducing delays and operational costs.",
            "Cost Savings: Lowers expenses associated with manual processes.",
            "Real-Time Data Insights: Offers valuable data for better decision-making and revenue management.",
            "Better Revenue Management: Prevents revenue leakages and ensures accurate billing.",
            "Environmental Benefits: Reduces paper usage and promotes digital processes.",
            "Customer Empowerment: Provides consumers with immediate billing information and flexible payment options.",
            "Increased Flexibility: Adapts to various utility services and customer needs.",
        ],
        byTheline: "By implementing spot billing, utility companies can modernize their billing processes, reduce inefficiencies, and provide superior service to their customers.",
    },
    {
        id: 5,
        projectTitle: "RFID",
        projectName: "RFID-Based Patient Tracking System",
        text: "(Patient Tracker)",
        industry: "Healthcare",
        platform: "Integrated System",
        caseStudyPdfLink: "",
        demoVideoLink: "",
        introductionTitle: "What is RFID Revolutionizing Healthcare with Real-Time Monitoring System?",
        introductionText:
            "An RFID-based patient tracking system with UHF RFID readers and tag-enabled wristbands is a cutting-edge technology solution designed to enhance patient safety, operational efficiency, and overall patient care within hospitals or healthcare campuses. By leveraging Radio Frequency Identification (RFID) technology, this system provides real-time tracking and identification of patients, ensuring better management of patient movements and improving the overall healthcare experience.",
        howToWork: 'How RFID-Based Patient Tracking Systems Work?',
        howToWorkPoints: [
            "RFID Wristbands: Each patient is provided with a wristband containing an RFID tag upon admission. These tags hold unique identification data specific to the patient.",
            "RFID Readers: RFID scanners are strategically installed at the entrances and exits of each ward, as well as critical points throughout the hospital.",
            "Real-Time Tracking: The RFID system captures and logs patient movements in real-time, updating their location within the hospital’s database.",
            "Data Integration: The tracking data integrates seamlessly with the hospital’s management system, giving healthcare providers instant access to patient location information.",
        ],
        aboutClient:
            "Our client, the Ahmedabad Civil Hospital, faced unprecedented challenges during the COVID-19 pandemic. Operating one of the world’s largest COVID-19 facilities with 1,200 beds, they struggled with limited staff and an overwhelming number of patients, resulting in poor management and critical issues highlighted by the media and in the state high court.",
        clientPoints: [
            "They have opened special 1200 BED hospitals only for COVID patient which was one of the largest bed capacity Hospital in world during COVID Period at where a huge numbers of COVID patients were admitted in their hospitals on daily basis and as they have limited staff during COVID and huge patient were admitted it was difficult for them handle situation result poor management was challenge in State high court.",
            "So higher official Comate decided   to deploy latest technology automatic Patient tracking system which help them to handle situation and better management with limited resources as well as keep track of the admitted patient’s whereabouts.",
            "So we came up with this innovative solution to fulfil their requirement and asked us to successfully run system within 7 to 10 days which report they wish to submit in State high court to show their action during this pandemic situation.",
        ],
        challangesProblems: [
            "Limited nursing and doctor staff led to poor patient management.",
            "Lack of proper patient registration and tracking systems.",
            "Patients interchanged beds without record, complicating patient identification.",
            "Instances of patients leaving the hospital without discharge, spreading the virus.",
            "Patients wandering within the premises, breaching isolation protocols.",
            "Patients wandering within the premises, breaching isolation protocols.",
            "Media highlighted issues like incorrect identification of deceased patients.",
        ],
        solutionHeadline: 'We implemented a comprehensive RFID-based patient tracking system to address these challenges:',
        solution: [
            "Registration System: Patients received RFID-enabled wristbands upon admission, containing unique identification, contact details, medical information, and assigned ward/bed data.",
            "Tracking System: RFID scanners were installed at strategic locations, including entrances/exits of wards, ICUs, operation theatres, and critical areas within the hospital.",
            "Real-Time Tracking: Patient movements were tracked in real-time, with data sent to a central database.",
            "Centralized Monitoring System: A secure interface allowed hospital staff to continuously monitor patient locations, supported by a real-time dashboard for government officials.",
            "Alerts and Notifications: The system generated alerts for unauthorized movements, patients leaving without discharge, and other critical scenarios.",
        ],
        diagrams: [
            RFIDimg1,
            RFIDimg2,
        ],
        technologies: [
            "Technology: RFID scanners at ward entrances/exits send notifications to the system, updating patient locations.",
            "Alerts: Alerts are generated for patients not in their assigned wards or attempting to leave without discharge.",
        ],
        technologyicons: [
            SCANNDOIcon1,
            SCANNDOIcon2,
            SCANNDOIcon3,
            SCANNDOIcon4
        ],
        benefitsHeadline: 'Benefits of RFID-Based Patient Tracking Systems',
        services: [
            "Enhanced Patient Safety: Continuous monitoring ensures patients do not wander into unsafe areas, with immediate alerts for unauthorized movements.",
            "Improved Operational Efficiency: Real-time tracking reduces the time staff spend locating patients, allowing more focus on patient care and efficient resource utilization.",
            "Accurate Patient Identification: RFID wristbands ensure precise patient identification, minimizing medical errors related to misidentification.",
            "Streamlined Workflow: Automated data capture through RFID reduces manual entry, minimizing errors and saving time.",
            "Compliance and Reporting: The system provides accurate logs of patient movements, essential for regulatory compliance and detailed reporting.",
            "Enhanced Security: Prevents unauthorized access to restricted areas, ensuring patients are always in appropriate locations.",
        ],
        byTheline: 'By implementing an RFID-based patient tracking system, hospitals can significantly improve patient safety, operational efficiency, and overall management. This innovative solution not only addresses immediate challenges but also sets the foundation for a smarter, more responsive healthcare environment.',
    },
    {
        id: 6,
        projectTitle: "Traffic Ticketing Solution",
        projectName: "Streamlining Traffic Violation Management with Technology",
        text: "(Traffic Ticketing Solution System)",
        industry: "Govt. Services",
        platform: "POS Device",
        caseStudyPdfLink: "",
        demoVideoLink: "",
        introductionTitle: "What is Traffic Ticketing Solution System?",
        introductionText:
            "A smart traffic ticketing solution system is an advanced electronic solution used by traffic authorities to efficiently issue and manage fines for traffic violations. By leveraging technologies such as automated cameras, handheld devices, and digital payment systems, this system streamlines the process of recording violations, issuing fines, and collecting payments. The implementation of a smart traffic ticketing solution system aims to enhance the efficiency, accuracy, and effectiveness of traffic enforcement, ultimately improving road safety and compliance with traffic regulations.",
        aboutClient:
            "Our client, the Odisha State Traffic Police Department, faced significant challenges with their traditional challan system. Issues included non-payment of fines, lack of historical violation data, and difficulties tracking officer conduct and potential bribery.",
        clientPoints: [
            "To overcome this problem we gave them the solution of E- Challan system where they can generate the challan and take fine on the spot without any delay",
            "challan once generated will be uploaded on the central database so the government can keep track of challans",
            "various modes of online payment enabled in the device",
            "the fine is directly deposited in the department’s bank account and chip reading of the license and the RC book to make sure the person is the rightful owner of the vehicle and is not driving any stolen vehicle.",
        ],
        challangesProblems: [
            "Manual Process: Traffic violations were manually recorded by officers, leading to inconsistencies.",
            "Paper-Based System: Physical challans were issued and fines were paid at limited centers, causing delays.",
            "Enforcement Issues: Inefficient enforcement led to low compliance with traffic regulations.",
        ],
        solutionHeadline: 'We provided a comprehensive traffic ticketing solution system to address these issues. Our solution included multiple components to ensure efficient and transparent traffic violation management:',
        solution: [
            "Technology Adoption: Handheld Payment POS Devices - Features: Devices equipped with NFC, RFID readers, and payment processing capabilities. Functions: Real-time recording of violations, instant challan issuance, and on-the-spot fine payment. License Chip Reading - Features: Smart chips embedded in driver’s licenses containing driver information. Functions: Quick retrieval of driver details for accurate violation recording.",
            "Software Development: Traffic Ticketing Solution Software: Developed software for real-time violation processing and data integration. Integration: Connected with traffic management systems and databases for seamless operations.",
        ],
        diagrams: [
            ECHALLANimg1,
        ],
        technologies: [
            "Point-of-Sale (POS) Devices - Function: Allow traffic officers to record violations, issue traffic ticketing solutions, and accept on-the-spot payments. Technology: Handheld devices with NFC (Near Field Communication), RFID (Radio-Frequency Identification) readers, and payment processing capabilities.",
            "License Chip Readers - Function: Quickly retrieve driver information from smart chips embedded in driver’s licenses. Technology: Handheld readers with RFID or NFC technology to read chip data.",
            "Traffic Ticketing Solution Software Platform - Function: Centralized system for managing violation data, processing traffic ticketing solutions, and integrating with other databases. Technology: Cloud-based software solutions with real-time data processing capabilities.",
            "Data Integration - Function: Integrates data from various sources (cameras, handheld devices) and existing traffic databases. Technology: APIs (Application Programming Interfaces) and middleware solutions to facilitate data exchange.",
            "Mobile and Wireless Networks - Function: Enable real-time data transmission between handheld devices, cameras, and the central database. Technology: 4G/5G networks, Wi-Fi, and Bluetooth for seamless connectivity.",
            "GPS and GIS Technology - Function: Track the location of violations and enforcement activities. Technology: Global Positioning System (GPS) and Geographic Information System (GIS) for accurate mapping and tracking.",
            "Digital Payment Gateways - Function: Facilitate secure online payments for fines. Technology: Payment gateways such as PayPal, Stripe, or local banking solutions integrated into the traffic ticketing solution platform.",
            "Mobile Payment Solutions - Function: Allow violators to pay fines using mobile wallets and apps. Technology: Integration with popular mobile payment services like Apple Pay, Google Pay, or local e-wallets.",
        ],
        technologyicons: [
            SCANNDOIcon1,
            SCANNDOIcon2,
            SCANNDOIcon3,
            SCANNDOIcon4
        ],
        services: [
            "Detection of Violation: Automated and Manual: Automated cameras and traffic officers using handheld POS devices detect violations.",
            "Verification and Recording: License Chip Reading: Officers use POS devices to scan the driver’s license chip, retrieving driver information. Data Entry: Violation details are entered into the system in real-time.",
            "Issuance of Traffic Ticketing Solution: The POS device generates an traffic ticketing solution on the spot, which is provided to the violator. Digital Record: A digital copy is stored in the central system.",
            "Fine Payment: On-the-Spot Payment: Violators can pay fines immediately using the POS device (credit card, debit card, mobile payment). Online Payment: Option to pay later through an online portal or mobile app.",
            "Deployment of systems across various terminals",
        ],
        benefitsOf: 'Benefits of Traffic Ticketing Solution System',
        benefitsPoints: [
            "Enhanced Traffic Enforcement: Real-time data processing improves the accuracy and efficiency of traffic violation management.",
            "Improved Compliance: Automated and transparent processes increase compliance with traffic regulations.",
            "Increased Revenue: Streamlined fine collection process ensures higher revenue for traffic authorities.",
            "Reduced Corruption: Digital records and on-the-spot payments reduce opportunities for bribery.",
            "Better Resource Management: Efficient use of traffic enforcement resources leads to better management and allocation.",
        ],
    },
];

export {
    projectData
}