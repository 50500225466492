import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Projects() {

    const navigate = useNavigate();

    return (

        <>
            <div className="container-fluid py-5 position-relative" style={{ width: '100%', height: '100%' }}>
                <div
                    style={{
                        backgroundImage: `url("/img/BackgroundDesigns/test3.png")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: 0.3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1
                    }}
                ></div>
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="img/ITMS/image1.jpg" className="img-fluid w-100 rounded" alt="Web design project" />
                                    <div className="project-content">
                                        <a href="https://ikartsolutions.com/" className="text-center">
                                            <h4 className="text-secondary">Web Design</h4>
                                            <p className="m-0 text-white">Web Analysis</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                            <h5 className="text-primary">ITMS</h5>
                            <h1 className="mb-4">Integrated Transport Management Systems</h1>
                            <p>ITMS stands for Integrated Transport Management System. It is an integrated system meant for transport hubs in smart cities. ITMS is developed to elevate the commuter’s overall experience using technology, attracting more people to use mass transport systems rather than personal vehicles for their daily commuting.</p>
                            <p className="mb-4">ITMS puts an end to the chaotic commuting experience, such as standing in queues to get tickets, handling of cash for ticket issuance, endless waits for buses, and chaos during boarding. Live tracking of the bus fleet ensured that passengers can get precise timing of their bus arrival/deparature.</p>
                            <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                onClick={() => navigate("/template", { state: { id: 1, screen: 'ITMS' } })}>More Details</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 position-relative" style={{ width: '100%', height: '100%' }}>
                <div
                    style={{
                        backgroundImage: `url("/img/BackgroundDesigns/test3.png")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: 0.3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1
                    }}
                ></div>
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                            <h5 className="text-primary">POS</h5>
                            <h1 className="mb-4">Smart Parking Ticketing Solution</h1>
                            <p>Our client, facing the challenges of managing parking fee collection booths and exit gates efficiently, sought a solution to streamline the process while enhancing the overall experience for vehicle owners.</p>
                            <p className="mb-4">iKart Solutions stepped in to address these pain points with a robust, innovative system.</p>
                            <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                onClick={() => navigate("/template", { state: { id: 2, screen: 'POS' } })}>More Details</button>
                        </div>
                        <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="img/ParkingSystem/image1.jpeg" className="img-fluid w-100 rounded" alt="Web design project" />
                                    <div className="project-content">
                                        <a href="https://ikartsolutions.com/" className="text-center">
                                            <h4 className="text-secondary">Web Design</h4>
                                            <p className="m-0 text-white">Web Analysis</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 position-relative" style={{ width: '100%', height: '100%' }}>
                <div
                    style={{
                        backgroundImage: `url("/img/BackgroundDesigns/test3.png")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: 0.3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1
                    }}
                ></div>
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="img/ScanNDo/ScanNDo3.jpg" className="img-fluid w-100 rounded" alt="Web design project" />
                                    <div className="project-content">
                                        <a href="https://ikartsolutions.com/" className="text-center">
                                            <h4 className="text-secondary">Web Design</h4>
                                            <p className="m-0 text-white">Web Analysis</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                            <h5 className="text-primary">ScanNDo</h5>
                            <h1 className="mb-4">QR Code Based Food Ordering n Delivery System</h1>
                            <p>SCANnDo was a simple solution to allow small restaurateurs to bring their business online and create their online presence. SCANnDo offers complete online food ordering experience i.e. browse of food menu, order cart, payment processing, order status tracking, etc.</p>
                            <p className="mb-4">It is ideal solution for small business owners to increase their business foot print using online medium.</p>
                            <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                onClick={() => navigate("/template", { state: { id: 3, screen: 'ScanNDo' } })}>More Details</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 position-relative" style={{ width: '100%', height: '100%' }}>
                <div
                    style={{
                        backgroundImage: `url("/img/BackgroundDesigns/test3.png")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: 0.3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1
                    }}
                ></div>
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                            <h5 className="text-primary">EMSB</h5>
                            <h1 className="mb-4">Electricity Meter Spot Billing</h1>
                            <p>Spot billing for Power, Water & Gas type utility spot billing & Payment Collection  system where meter readings are taken and bills are generated on-site and digital payment option also available for spot payment Collection , providing immediate feedback to consumers. This system aims to enhance billing accuracy, reduce delays, and improve customer satisfaction.</p>
                            <p className="mb-4">This case study explores the implementation and impact of spot billing in a mid-sized city, examining the processes involved, challenges faced, and outcomes achieved.</p>
                            <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                onClick={() => navigate("/template", { state: { id: 4, screen: 'EMSB' } })}>More Details</button>
                        </div>
                        <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="img/EMSB/emsb.png" className="img-fluid w-100 rounded" alt="Web design project" />
                                    <div className="project-content">
                                        <a href="https://ikartsolutions.com/" className="text-center">
                                            <h4 className="text-secondary">Web Design</h4>
                                            <p className="m-0 text-white">Web Analysis</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 position-relative" style={{ width: '100%', height: '100%' }}>
                <div
                    style={{
                        backgroundImage: `url("/img/BackgroundDesigns/test3.png")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: 0.3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1
                    }}
                ></div>
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="img/EChallan/coverimg.jpg" className="img-fluid w-100 rounded" alt="Web design project" />
                                    <div className="project-content">
                                        <a href="https://ikartsolutions.com/" className="text-center">
                                            <h4 className="text-secondary">Web Design</h4>
                                            <p className="m-0 text-white">Web Analysis</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                            <h5 className="text-primary">Traffic Ticketing Solution</h5>
                            <h1 className="mb-4">Electric Smart Traffic Ticketing Solution System</h1>
                            <p>A smart traffic ticketing solution is an electronic system used by traffic authorities to issue and manage fines for traffic violations.</p>
                            <p className="mb-4">The system leverages advanced technologies such as automated cameras, handheld devices, and digital payment systems to streamline the process of recording violations, issuing fines, and collecting payments. The implementation of smart traffic ticketing solution systems aims to enhance the efficiency, accuracy, and effectiveness of traffic enforcement, ultimately improving road safety and compliance with traffic regulations.</p>
                            <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                onClick={() => navigate("/template", { state: { id: 5, screen: 'Traffic Ticketing Solution' } })}>More Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
