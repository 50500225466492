import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

export default function RequestADemo() {
    const [formData, setFormData] = useState({
        from_name: '',
        email: '',
        project: '',
        message: '',
        captcha: '',
    });

    const [errors, setErrors] = useState({});
    const [captchaCode, setCaptchaCode] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.from_name) formErrors.from_name = "Name is required";
        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!formData.project) formErrors.project = "Project is required";
        if (!formData.message) formErrors.message = "Message is required";
        if (!formData.captcha) {
            formErrors.captcha = "Captcha is required";
        } else if (formData.captcha !== captchaCode) {
            formErrors.captcha = "Captcha is incorrect";
        }
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            emailjs.sendForm('service_zp1ks29', 'template_ohepfdp', e.target, 'xoHSrk5H0nW1dSqcC')
                .then((result) => {
                    console.log(result.text);
                    alert("Success! Email sent successfully.");
                    setFormData({ from_name: '', email: '', project: '', message: '', captcha: '' });
                    generateCaptcha();
                    setIsModalOpen(false);
                }, (error) => {
                    console.error(error.text);
                });
        } else {
            setErrors(formErrors);
        }
    };

    const generateCaptcha = () => {
        let character = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890';
        let captcha = '';
        for (let i = 0; i < 6; i++) {
            captcha += character.charAt(Math.floor(Math.random() * character.length));
        }
        setCaptchaCode(captcha);
    };

    useEffect(() => {
        generateCaptcha();
    }, []);

    const handleRefreshCaptcha = (e) => {
        e.preventDefault();
        generateCaptcha();
    };

    return (
        isModalOpen && (
            <div>
                <div className="container-fluid py-3 mt-3">
                    <div className="container py-3">
                        <div className="text-center mx-auto pb-3 fadeIn" style={{ maxWidth: '600px' }}>
                            <h5 className="text-primary">Get In Touch</h5>
                            <h1 className="mb-3">Demo Request Form</h1>
                        </div>
                        <div className="contact-detail position-relative p-3 fadeIn">
                            <div className="row g-3">
                                <div className="col-lg-12">
                                    <div className="p-3 rounded contact-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-4">
                                                <input type="text" className="form-control border-0 py-3" placeholder="Your Name" name="from_name" onChange={handleChange} value={formData.from_name} />
                                                {errors.from_name && <p className="text-danger">{errors.from_name}</p>}
                                            </div>
                                            <div className="mb-4">
                                                <input type="email" className="form-control border-0 py-3" placeholder="Your Email" name="email" onChange={handleChange} value={formData.email} />
                                                {errors.email && <p className="text-danger">{errors.email}</p>}
                                            </div>
                                            <div className="mb-4">
                                                <input type="text" className="form-control border-0 py-3" placeholder="Project" name="project" onChange={handleChange} value={formData.project} />
                                                {errors.project && <p className="text-danger">{errors.project}</p>}
                                            </div>
                                            <div className="mb-4">
                                                <textarea className="w-100 form-control border-0 py-3" rows="3" cols="5" placeholder="Message" name="message" onChange={handleChange} value={formData.message}></textarea>
                                                {errors.message && <p className="text-danger">{errors.message}</p>}
                                            </div>
                                            <div className="mb-4 d-flex align-items-center" style={styles.captchaContainer}>
                                                <div className="form-control border-0" style={styles.captchaCode}>{captchaCode}</div>
                                                <input type="text" className="form-control border-0 py-3" style={styles.captchaInput} placeholder="Enter Captcha" name="captcha" onChange={handleChange} value={formData.captcha} />
                                                <button className="btn bg-primary text-white py-3" style={styles.captchaButton} onClick={handleRefreshCaptcha}>Refresh</button>
                                            </div>
                                            {errors.captcha && <p className="text-danger">{errors.captcha}</p>}
                                            <div className="text-start mt-3">
                                                <button className="btn bg-primary text-white py-3 px-5" type="submit">Send Message</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );

}

const styles = {
    captchaContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    captchaCode: {
        flex: '1 1 100px',
        margin: '5px 0',
        padding: '10px',
        fontSize: '1.5em',
        textAlign: 'center',
        background: '#f8f9fa',
    },
    captchaInput: {
        flex: '2 1 200px',
        margin: '5px',
        padding: '10px',
        fontSize: '1em',
    },
    captchaButton: {
        flex: '1 1 100px',
        padding: '10px',
        fontSize: '1em',
        whiteSpace: 'nowrap',
    },
};