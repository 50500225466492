import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUser, faExclamationCircle, faLightbulb, faCogs, faIndustry, faDesktop, faFileAlt, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import headerimage from '../assets/img/headerIMG.jpg';
import textBackgoundImg from '../assets/img/BackgroundDesigns/pattern-4.png';
import '../assets/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { projectData } from './projectData';
import DetailForm from './detailForm';

const iconMapping = {
    faCogs: faCogs,
    faIndustry: faIndustry,
    faDesktop: faDesktop,
};

export default function Template() {
    const navigate = useNavigate();
    const [details, setDetails] = useState({});
    const { state } = useLocation();

    const [isVisible, setIsVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top
    }, []);

    useEffect(() => {
        if (state && state.screen) {
            const projectDetails = projectData.find(item => item.projectTitle === state.screen);
            setDetails(projectDetails);
        } else {
            navigate('/');
        }
    }, [state, navigate]);

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
            const modalElement = new window.bootstrap.Modal(document.getElementById('exampleModalToggle'));
            modalElement.show();
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleClose = () => setShowModal(false);

    return (
        <div style={styles.container}>
            {showModal &&
                <div>
                    <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <DetailForm />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <header style={styles.header}>
                <div style={styles.imageContainer}>
                    <img src={headerimage} alt="Background" style={styles.headerBackgroundImage} />
                </div>
                <div style={styles.headerContent}>
                    <div style={styles.titles}>
                        <h1 style={styles.headerH1}>{details.projectTitle}</h1>
                        <h2 style={styles.headerH2}>{details.projectName}</h2>
                    </div>
                    <div style={styles.logo}>
                        <span style={styles.logoText}>{details.text}</span>
                    </div>
                </div>
            </header>

            <div className="row bg-secondary" style={styles.iconContainer}>
                <div className="col-lg-6 col-md-6" style={styles.iconContent} data-wow-delay=".1s">
                    <FontAwesomeIcon icon={faIndustry} style={styles.iconBeforeText} />Industry/Segment : {details.industry}
                </div>
                <div className="col-lg-6 col-md-6" style={styles.iconContent} data-wow-delay=".1s">
                    <FontAwesomeIcon icon={faDesktop} style={styles.iconBeforeText} />Platform : {details.platform}
                </div>
            </div>

            <div style={styles.textContent}>
                <h3 style={styles.sectionH3} className="row">
                    <div className="col-lg-10" style={{ textAlign: 'left' }} data-wow-delay=".1s">
                        <FontAwesomeIcon icon={faInfoCircle} style={styles.iconBeforeText} />
                        {details.introductionTitle}
                    </div>
                    <div className="col-lg-1" style={{ textAlign: 'center' }} data-wow-delay=".1s" onClick={() => setShowModal(true)}>
                        {details.caseStudyPdfLink && (
                            <div style={styles.floatingicon} title="View Case Study">
                                <a href={details.caseStudyPdfLink} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFileAlt} style={styles.attractiveIcon} />
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-1" style={{ textAlign: 'center' }} data-wow-delay=".1s">
                        {details.demoVideoLink && (
                            <div style={styles.floatingicon} title="Watch Demo Video">
                                <a href={details.demoVideoLink} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} style={styles.attractiveIcon} />
                                </a>
                            </div>
                        )}
                    </div>
                </h3>
                <section style={styles.section}>
                    <p style={styles.sectionP}>{details.introductionText}</p>
                </section>
            </div>

            {details.howToWorkPoints && (
                <div style={styles.textContent}>
                    <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faExclamationCircle} style={styles.iconBeforeText} />{details.howToWork}</h3>
                    <section style={styles.section}>
                        <ul style={styles.ul}>
                            {details.howToWorkPoints && details.howToWorkPoints.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </section>
                </div>
            )}

            <div style={styles.textContent}>
                <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faUser} style={styles.iconBeforeText} />About Client</h3>
                <section style={styles.section}>
                    <p style={styles.sectionP}>{details.aboutClient}</p>
                    <ul style={styles.ul}>
                        {details.clientPoints && details.clientPoints.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                    </ul>
                </section>
            </div>

            <div style={styles.textContent}>
                <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faExclamationCircle} style={styles.iconBeforeText} />Challenges / Problem Statement</h3>
                <section style={styles.section}>
                    <ul style={styles.ul}>
                        {details.challangesProblems && details.challangesProblems.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                    </ul>
                </section>
            </div>

            <div style={styles.textContent}>
                <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faLightbulb} style={styles.iconBeforeText} />Solution</h3>
                <div className="row">
                    <section className="col-md-12" style={styles.section}>
                        <p style={styles.sectionP}>{details.solutionHeadline}</p>
                        <ol style={styles.ol}>
                            {details.solution && details.solution.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ol>
                    </section>
                    {details.diagrams && details.diagrams.map((path, index) => (
                        <div key={index} className="col-md-6" style={styles.solutionImage}>
                            <img src={path} alt="System Architecture Overview" style={styles.architectureImg} />
                        </div>
                    ))}
                </div>
            </div>

            <div style={styles.textContent}>
                <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faLightbulb} style={styles.iconBeforeText} />Technology</h3>
                <section style={styles.section}>
                    <ul style={styles.ul}>
                        {details.technologies && details.technologies.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                    </ul>
                    <div style={styles.technologies}>
                        {details.technologyicons && details.technologyicons.map((icon, index) => (
                            <div key={index} style={{ ...styles.floatArea, animationName: index % 2 === 0 ? 'float' : 'floatReverse' }}>
                                <img src={icon} alt={`Technology ${index}`} style={index % 2 === 0 ? styles.technologyImg1 : styles.technologyImg2} />
                            </div>
                        ))}
                    </div>
                </section>
            </div>

            <div style={styles.textContent}>
                <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faExclamationCircle} style={styles.iconBeforeText} />Our Services</h3>
                <section style={styles.section}>
                    <p style={styles.sectionP}>{details.benefitsHeadline}</p>
                    <ul style={styles.ul}>
                        {details.services && details.services.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                    </ul>
                    <p style={styles.sectionP}>{details.byTheline}</p>
                </section>
            </div>

            {details.benefitsOf && (
                <div style={styles.textContent}>
                    <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faExclamationCircle} style={styles.iconBeforeText} />{details.benefitsOf}</h3>
                    <section style={styles.section}>
                        <ul style={styles.ul}>
                            {details.benefitsPoints && details.benefitsPoints.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </section>
                </div>
            )}

            {/* scrollToTop Button */}
            <div className="scroll-to-top">
                {isVisible && (
                    <div onClick={scrollToTop}>
                        <FontAwesomeIcon icon={faArrowUp} className="scroll-to-top-icon" />
                    </div>
                )}
            </div>

        </div>
    );
}

const floatAnimation = `
    @keyframes float {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(0);
        }
    }

    @keyframes swipe {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-15px);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes floatReverse {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(20px);
        }
        100% {
            transform: translateY(0);
        }
    }
`;

const styles = {
    container: {
        width: '100%',
        margin: '0 auto',
        overflow: 'hidden',
        backgroundImage: `url(${textBackgoundImg})`,
        backgroundSize: 'contain',
    },
    header: {
        position: 'relative',
        color: 'white',
        overflow: 'hidden',
        zIndex: 1,
    },
    imageContainer: {
        position: 'relative',
        width: '100%',
        height: '165px',
        overflow: 'hidden',
    },
    headerBackgroundImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        clipPath: 'url(#waveClipPath)',
    },
    headerContent: {
        position: 'absolute',
        top: '48%',
        left: '22px',
        transform: 'translateY(-50%)',
        zIndex: 2,
        width: 'calc(55% - 44px)',
        padding: '0 20px',
    },
    titles: {
        textAlign: 'left',
        color: 'white',
    },
    headerH1: {
        margin: 0,
        fontSize: '36px',
        color: 'white',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    },
    headerH2: {
        margin: '10px 0 0',
        fontSize: '24px',
        color: 'white',
        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
    },
    logoText: {
        fontSize: '16px',
        fontStyle: 'italic',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        padding: '10px 0',
        flexDirection: 'row',
    },
    iconContent: {
        textAlign: 'center',
        fontSize: '18px',
        color: 'white',
    },
    textContent: {
        margin: '20px 25px',
        fontSize: '18px',
        padding: '10px',
        borderRadius: '18px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    section: {
        padding: '20px 25px',
        borderRadius: '8px',
        transition: 'transform 0.3s ease-in-out',
    },
    sectionH3: {
        padding: '10px 25px',
        color: '#f55b14',
        fontSize: '24px',
        fontWeight: 'bold',
        borderBottom: '2px solid #f55b14',
        marginBottom: '0',
    },
    sectionP: {
        color: '#555',
        lineHeight: '1.8',
        margin: '10px 0',
    },
    iconBeforeText: {
        marginRight: '10px',
    },
    li: {
        color: '#555',
        lineHeight: '1.8',
        margin: '10px 0',
    },
    ul: {
        paddingLeft: '20px',
        marginBottom: '20px',
    },
    ol: {
        paddingLeft: '20px',
        marginBottom: '20px',
    },
    architectureImg: {
        width: '100%',
        marginTop: '20px',
    },
    technologies: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        marginTop: '30px',
    },
    technologyImg1: {
        width: '100px',
        height: '100px',
        margin: '10px',
        animation: 'float 3s ease-in-out infinite',
    },
    technologyImg2: {
        width: '100px',
        height: '100px',
        margin: '10px',
        animation: 'floatReverse 3s ease-in-out infinite',
    },
    floatArea: {
        position: 'relative',
    },
    floatingicon: {
        animation: 'swipe 3s ease-in-out infinite',
        position: 'relative',
        transition: 'transform 0.5s, color 0.5s, box-shadow 0.5s',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2) rotate(10deg)',
            color: '#123087',
            textShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            boxShadow: '0 0 20px rgba(255, 0, 0, 0.8)',
        },
        '&:active': {
            transform: 'scale(1)',
        },
    },
    attractiveIcon: {
        width: '33px',
        height: '33px',
        color: '#f50000',
        transition: 'transform 0.5s, color 0.5s',
        cursor: 'pointer',
    },

    // Media Queries for Responsiveness
    '@media (max-width: 768px)': {
        headerContent: {
            width: 'calc(90% - 44px)',
        },
        headerH1: {
            fontSize: '28px',
        },
        headerH2: {
            fontSize: '20px',
        },
        sectionH3: {
            fontSize: '20px',
        },
        iconContainer: {
            flexDirection: 'column',
        },
        iconContent: {
            marginBottom: '10px',
        },
        technologies: {
            flexDirection: 'column',
        },
    },
    '@media (max-width: 576px)': {
        headerContent: {
            width: 'calc(100% - 44px)',
            top: '40%',
            left: '10px',
        },
        headerH1: {
            fontSize: '24px',
        },
        headerH2: {
            fontSize: '18px',
        },
        sectionH3: {
            fontSize: '18px',
        },
        iconContainer: {
            padding: '20px 10px',
        },
        textContent: {
            margin: '15px',
            fontSize: '16px',
            padding: '8px',
            borderRadius: '14px',
        },
        technologies: {
            flexDirection: 'column',
        },
    },
    '@media (max-width: 480px)': {
        headerH1: {
            fontSize: '20px',
        },
        headerH2: {
            fontSize: '16px',
        },
        sectionH3: {
            fontSize: '16px',
        },
        section: {
            padding: '15px 20px',
        },
        iconContainer: {
            flexDirection: 'column',
            padding: '15px 5px',
        },
        textContent: {
            margin: '10px',
            padding: '5px',
            borderRadius: '10px',
        },
    },
    '@media (max-width: 360px)': {
        headerH1: {
            fontSize: '18px',
        },
        headerH2: {
            fontSize: '14px',
        },
        sectionH3: {
            fontSize: '14px',
        },
        section: {
            padding: '10px 15px',
        },
        iconContainer: {
            flexDirection: 'column',
            padding: '10px 0',
        },
        textContent: {
            margin: '5px',
            padding: '5px',
            borderRadius: '8px',
        },
    },
};

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = floatAnimation;
document.head.appendChild(styleSheet);
