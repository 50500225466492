import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { productData } from './productData';

export default function Products() {
    const [details, setDetails] = useState({});
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (state && state.id) {
            const projectDetails = productData.find(item => item.id === state.id);
            setDetails(projectDetails || {});
        } else {
            navigate('/');
        }
    }, [state, navigate]);

    return (
        <>
            <div className="container-fluid project py-5">
                <div className="container py-5">
                    <div
                        className="text-center mx-auto pb-5 wow fadeIn"
                        data-wow-delay=".3s"
                        style={{ maxWidth: "600px" }}
                    >
                        <h5 className="text-primary">Our Products</h5>
                        <h1>{details.product || 'Our Recently New Products'}</h1>
                    </div>

                    <div className="row g-5">
                        {details.prodctData ? (
                            details.prodctData.map((item, index) => (
                                <div key={index} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                                    <div className="project-item">
                                        <div className="project-img">
                                            <img
                                                src={item.prodctImages}
                                                className="img-fluid w-100 rounded"
                                                alt={item.productName}
                                            />
                                            <div className="project-content">
                                                <a href="#" className="text-center">
                                                    <h4 className="text-secondary">{item.productName}</h4>
                                                    <p className="m-0 text-white">{item.description}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center">No product details available.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}