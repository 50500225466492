import PDPII from "../assets/img/Products/Printers/PDP 2.JPEG";
import DTPIII from "../assets/img/Products/Printers/DTP III.jpeg";
import DTPIIIDotMatrix from "../assets/img/Products/Printers/DTP III Dot Matrix.jpg";
import DTPIV from "../assets/img/Products/Printers/DTP IV.jpeg";
import DTPIVThermalTransfer from "../assets/img/Products/Printers/DTP IV Thermal Transfer.jpg";
import PTPII from "../assets/img/Products/Printers/PTP II (1).jpeg";
import PTPIII from "../assets/img/Products/Printers/PTP III (1).jpeg";
import PTPIV from "../assets/img/Products/Printers/DTP III.jpeg";

import P10 from "../assets/img/Products/Point Of Sales Devices/P10.jpg";
import P30 from "../assets/img/Products/Point Of Sales Devices/P 30.jpg";
import AHTPOS from "../assets/img/Products/Point Of Sales Devices/AHRT Handheld.jpg";
import HHTII from "../assets/img/Products/Point Of Sales Devices/HHT II.jpg";
import AHRTScanning from "../assets/img/Products/Point Of Sales Devices/AHRT Handheld.jpg";
import AIBasedRetailPOS from "../assets/img/Products/Point Of Sales Devices/Smart AI based Retail POS.jpg";
import GC148 from "../assets/img/Products/Point Of Sales Devices/GC148.jpg";
import GC097 from "../assets/img/Products/Point Of Sales Devices/GC097.jpg";

import A330 from "../assets/img/Products/Cash Drawers/330A.jpg";
import A405 from "../assets/img/Products/Cash Drawers/405A.jpg";
import D405 from "../assets/img/Products/Cash Drawers/405D.jpg";
import A460 from "../assets/img/Products/Cash Drawers/460.jpg";

import WiredBarcodeScanner from "../assets/img/Products/Scanners/2D Wired Scanner.jpg";
import WirelessBarcodeScanner from "../assets/img/Products/Scanners/2D Wireless Scanner.jpg";

import EcoSC101 from "../assets/img/Products/Time & Face Attendance Access Control Bio Metrics/Eco S C101.jpg";
import EcoSC110t from "../assets/img/Products/Time & Face Attendance Access Control Bio Metrics/Eco S C110t.jpg";
import RS10 from "../assets/img/Products/Time & Face Attendance Access Control Bio Metrics/RS 10.jpg";
import RS20 from "../assets/img/Products/Time & Face Attendance Access Control Bio Metrics/RS 20.jpg";
import EcoSC121ta from "../assets/img/Products/Time & Face Attendance Access Control Bio Metrics/Eco S C121ta.jpg";
import T5 from "../assets/img/Products/Time & Face Attendance Access Control Bio Metrics/T5.jpg";

import RF10 from "../assets/img/Products/Card Readers/RF 10.jpg";
import RF10M from "../assets/img/Products/Card Readers/RF 10M.jpg";
import UHF5M from "../assets/img/Products/Card Readers/UGF 5M.jpg";
import UHF15M from "../assets/img/Products/Card Readers/UHF 15M.jpg";

import F1 from "../assets/img/Products/Door Locks with Biometrics/F1.jpg";
import F2 from "../assets/img/Products/Door Locks with Biometrics/F2.jpg";

const productData = [
    {
        id: 1,
        product: "Printers",
        prodctData: [
            {
                productName: "PDP II",
                description: "2 inch Portable Dot Matrix Priter",
                prodctImages: PDPII
            },
            {
                productName: "DTP III",
                description: "3 inch Desktop Thermal Printer",
                prodctImages: DTPIII
            },
            {
                productName: "DTP III(Dot Matrix)",
                description: "3 inch Desktop Dot Matrix Printer",
                prodctImages: DTPIIIDotMatrix
            },
            {
                productName: "DTP IV",
                description: "4 inch Desktop Thermal Printer",
                prodctImages: DTPIV
            },
            {
                productName: "DTP IV(Thermal Transfer)",
                description: "4 inch Desktop Thermal Transfer Printer",
                prodctImages: DTPIVThermalTransfer
            },
            {
                productName: "PTP II",
                description: "2 inch Portable Thermal Printer",
                prodctImages: PTPII
            },
            {
                productName: "PTP III",
                description: "3 inch Portable Thermal Printer",
                prodctImages: PTPIII
            },
            {
                productName: "PTP IV",
                description: "4 inch Portable Thermal Printer",
                prodctImages: PTPIV
            },
        ],
    },
    {
        id: 2,
        product: "Point Of Sales Devices",
        prodctData: [
            {
                productName: "P10",
                description: "Android Handheld Payment POS with Printer",
                prodctImages: P10
            },
            {
                productName: "P 30",
                description: "Android Handheld Payment POS with Printer",
                prodctImages: P30
            },
            {
                productName: "AHT POS",
                description: "Android Handheld Terminal POS with Printer(Non Payment)",
                prodctImages: AHTPOS
            },
            {
                productName: "HHT II",
                description: "Hand Held Terminal with 2 inch Printer",
                prodctImages: HHTII
            },
            {
                productName: "AHRT Scanning",
                description: "Android Handheld Rugged Scanning Terminal",
                prodctImages: AHRTScanning
            },
            {
                productName: "AI Based Retail POS",
                description: "All in one Retail/Convinience Store POS Device (Android/Windows based)",
                prodctImages: AIBasedRetailPOS
            },
            {
                productName: "GC148",
                description: "All in one windows based Retail/Convinience Store POS Device",
                prodctImages: GC148
            },
            {
                productName: "GC097",
                description: "All in one android based Retail/Convinience Store POS Device",
                prodctImages: GC097
            },
        ],
    },
    {
        id: 3,
        product: "Cash Drawers",
        prodctData: [
            {
                productName: "330A",
                description: "Sturdy Cash Drawer",
                prodctImages: A330
            },
            {
                productName: "405A",
                description: "Sturdy Cash Drawer",
                prodctImages: A405
            },
            {
                productName: "405D",
                description: "Sturdy Cash Drawer",
                prodctImages: D405
            },
            {
                productName: "460",
                description: "Sturdy Cash Drawer",
                prodctImages: A460
            },
        ],
    },
    {
        id: 4,
        product: "Scanners",
        prodctData: [
            {
                productName: "Wired Barcode Scanner",
                description: "Wired 2D Barcode scanner",
                prodctImages: WiredBarcodeScanner
            },
            {
                productName: "Wireless Barcode Scanner",
                description: "Wireless 2D Barcode Scanner",
                prodctImages: WirelessBarcodeScanner
            },
        ],
    },
    {
        id: 5,
        product: "Time & Face Attendance Access Control Bio Metrics",
        prodctData: [
            {
                productName: "Eco S C101",
                description: "Excel Output Attendance system with Table Top option",
                prodctImages: EcoSC101
            },
            {
                productName: "Eco S C110t",
                description: "Color display attendance System with TCP/IP",
                prodctImages: EcoSC110t
            },
            {
                productName: "RS 10",
                description: "Color Screen Attendance Recorder",
                prodctImages: RS10
            },
            {
                productName: "RS 20",
                description: "Color Screen Attendance Recorder with Access Control",
                prodctImages: RS20
            },
            {
                productName: "Eco S C121ta",
                description: "Color Screen Attendance Recorder simple with Access Control",
                prodctImages: EcoSC121ta
            },
            {
                productName: "T5",
                description: "Excel Output Attendance Reorder",
                prodctImages: T5
            },
        ],
    },
    {
        id: 6,
        product: "Card Readers",
        prodctData: [
            {
                productName: "RF 10",
                description: "Proximity Card Reader",
                prodctImages: RF10
            },
            {
                productName: "RF 10M",
                description: "Mifare Card Reader",
                prodctImages: RF10M
            },
            {
                productName: "UHF 5M",
                description: "UHF Reader 5 meter range",
                prodctImages: UHF5M
            },
            {
                productName: "UHF 15M",
                description: "UHF Reader 15 meter range",
                prodctImages: UHF15M
            },
        ],
    },
    {
        id: 7,
        product: "Door Locks with Biometrics",
        prodctData: [
            {
                productName: "F1",
                description: "Fingerprint Door lock",
                prodctImages: F1
            },
            {
                productName: "F2",
                description: "Fingerprint Door lock",
                prodctImages: F2
            },
        ],
    },
];

export {
    productData
}