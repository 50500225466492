import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
    const [formData, setFormData] = useState({
        from_name: '',
        email: '',
        project: '',
        message: '',
        captcha: '',
    });

    const [errors, setErrors] = useState({});
    const [captchaCode, setCaptchaCode] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.from_name) formErrors.from_name = "Name is required";
        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!formData.project) formErrors.project = "Project is required";
        if (!formData.message) formErrors.message = "Message is required";
        if (!formData.captcha) {
            formErrors.captcha = "Captcha is required";
        } else if (formData.captcha !== captchaCode) {
            formErrors.captcha = "Captcha is incorrect";
        }
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            emailjs.sendForm('service_zp1ks29', 'template_ohepfdp', e.target, 'xoHSrk5H0nW1dSqcC')
                .then((result) => {
                    console.log(result.text);
                    alert("Success! Email sent successfully.");
                    setFormData({ from_name: '', email: '', project: '', message: '', captcha: '' });
                    generateCaptcha();
                }, (error) => {
                    console.error(error.text);
                });
        } else {
            setErrors(formErrors);
        }
    };

    // Show button when page is scrolled up to a certain distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the visibility based on scroll
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const generateCaptcha = () => {
        let character = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890';
        let captcha = '';
        for (let i = 0; i < 6; i++) {
            captcha += character.charAt(Math.floor(Math.random() * character.length));
        }
        setCaptchaCode(captcha);
    };

    useEffect(() => {
        generateCaptcha();
    }, []);

    const handleRefreshCaptcha = (e) => {
        e.preventDefault();
        generateCaptcha();
    };

    return (
        <div>
            <div className="container-fluid py-3 mt-3">
                <div className="container py-3">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
                        <h5 className="text-primary">Get In Touch</h5>
                        <h1 className="mb-3">Contact for any query</h1>
                        <p className="mb-2">We'd love to hear from you! Whether you have questions, need support, or want to learn more about how IoTfied can transform your business, our team is here to help.</p>
                    </div>
                    <div className="contact-detail position-relative p-5">
                        <div className="row g-5 mb-5 justify-content-center">
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            {/* <i className="fas fa-map-marker-alt text-white"></i> */}
                                            <FontAwesomeIcon icon={faBuilding} className="text-white" />
                                            {/* <FontAwesomeIcon icon={faDesktop} className="text-white" /> */}
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Design Center</h4>
                                            <p className="m-0"><a href="https://maps.app.goo.gl/gcpJ9gUpesepi4GZ7" target="_blank" className="text-dark text-decoration-none">708 HareKrishna Complex, Opposite Kothawala Flats, Paldi, Ahmedabad, Gujarat, India 380006</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            {/* <i className="fa fa-industry text-white"></i> */}
                                            <FontAwesomeIcon icon={faChartLine} className="text-white" />
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Development Center</h4>
                                            <p className="m-0"><a href="https://maps.app.goo.gl/dDQ4EJ1ib9A5fbpVA" target="_blank" className="text-dark text-decoration-none">A 805, Unicus Shyamal, Shyamal Cross Rd, Ahmedabad, Gujarat 380015, India</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            <i className="fa fa-industry text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Production Center</h4>
                                            <p className="m-0"><a href="https://maps.app.goo.gl/nKPvXrR13xoqvWED8" target="_blank" className="text-dark text-decoration-none">B-11/2, 1st Floor, Electronics Estate, GIDC, Sector-25, Gandhinagar, Gujarat, India-382024</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            <i className="fa fa-phone text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Call Us</h4>
                                            <p className="m-0"><a href="tel:+918320116640" target="_blank" className="text-dark text-decoration-none">+91 83201 16640</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            <i className="fa fa-envelope text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Email Us</h4>
                                            <p className="m-0"><a href="mailto:sales@iotfied.com" target="_blank" className="text-dark text-decoration-none">sales@iotfied.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="p-5 h-100 rounded contact-map">
                                    <iframe
                                        className="rounded w-100 h-100"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4015973662617!2d72.58664647477084!3d23.045733865464555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1sen!2sin!4v1720012449460!5m2!1sen!2sin"
                                        style={{ border: '0' }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <div className="p-5 rounded contact-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <input type="text" className="form-control border-0 py-3" placeholder="Your Name" name="from_name" onChange={handleChange} value={formData.from_name} />
                                            {errors.from_name && <p className="text-danger">{errors.from_name}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <input type="email" className="form-control border-0 py-3" placeholder="Your Email" name="email" onChange={handleChange} value={formData.email} />
                                            {errors.email && <p className="text-danger">{errors.email}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <input type="text" className="form-control border-0 py-3" placeholder="Project" name="project" onChange={handleChange} value={formData.project} />
                                            {errors.project && <p className="text-danger">{errors.project}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <textarea className="w-100 form-control border-0 py-3" rows="3" cols="5" placeholder="Message" name="message" onChange={handleChange} value={formData.message}></textarea>
                                            {errors.message && <p className="text-danger">{errors.message}</p>}
                                        </div>
                                        <div className="mb-4 d-flex align-items-center" style={styles.captchaContainer}>
                                            <div className="form-control border-0" style={styles.captchaCode}>{captchaCode}</div>
                                            <input type="text" className="form-control border-0 py-3" style={styles.captchaInput} placeholder="Enter Captcha" name="captcha" onChange={handleChange} value={formData.captcha} />
                                            <button className="btn bg-primary text-white py-3" style={styles.captchaButton} onClick={handleRefreshCaptcha}>Refresh</button>
                                        </div>
                                        {errors.captcha && <p className="text-danger">{errors.captcha}</p>}
                                        <div className="text-start mt-3">
                                            <button className="btn bg-primary text-white py-3 px-5" type="submit">Send Message</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* scrollToTop Button */}
            <div className="scroll-to-top">
                {isVisible && (
                    <div onClick={scrollToTop}>
                        <FontAwesomeIcon icon={faArrowUp} className="scroll-to-top-icon" />
                    </div>
                )}
            </div>
        </div>
    );
}

const styles = {
    captchaContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    captchaCode: {
        flex: '1 1 100px',
        margin: '5px 0',
        padding: '10px',
        fontSize: '1.5em',
        textAlign: 'center',
        background: '#f8f9fa',
    },
    captchaInput: {
        flex: '2 1 200px',
        margin: '5px',
        padding: '10px',
        fontSize: '1em',
    },
    captchaButton: {
        flex: '1 1 100px',
        padding: '10px',
        fontSize: '1em',
        whiteSpace: 'nowrap',
    },
};