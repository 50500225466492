import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logoimg from '../assets/logo Samples/v3/f2@2x.png';

export default function Footer() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // scroll to top
    };

    return (
        <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
            <div className="container pt-5 pb-4">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6">
                        <Link to="/" onClick={scrollToTop}>
                            <img src={logoimg} className="img-fluid fw-bold d-block" style={{ height: 'auto', maxWidth: '100%', maxHeight: '75px' }} alt="logoimg" />
                        </Link>
                        <p className="mt-4 text-light">IoTfied was founded with a vision to transform the way businesses operate through advanced IoT technologies. Our journey began with a simple yet powerful idea: to bridge the gap between the physical and digital worlds, creating smarter, more efficient systems that drive growth and innovation. Today, we stand at the forefront of IoT innovation, committed to delivering cutting-edge solutions that exceed industry standards.</p>
                        <div className="d-flex hightech-link">
                            <a href="https://www.facebook.com/profile.php?id=61561684453435&mibextid=LQQJ4d" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-facebook-f text-primary"></i></a>
                            <a href="https://www.instagram.com/iotfied?igsh=MW81OG1kaDk4d25taA%3D%3D&utm_source=qr" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-instagram text-primary"></i></a>
                            <a href="https://www.linkedin.com/company/iotfied" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-0"><i className="fab fa-linkedin-in text-primary"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="text-secondary">Short Help Link</h3>
                        <div className="mt-4 d-flex flex-column short-link">
                            <NavLink to="/" onClick={scrollToTop} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Home</NavLink>
                            <NavLink to="/about" onClick={scrollToTop} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>About us</NavLink>
                            <NavLink to="/services" onClick={scrollToTop} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Our Services</NavLink>
                            <NavLink to="/blog" onClick={scrollToTop} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Our Blog</NavLink>
                            <NavLink to="/contact" onClick={scrollToTop} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Contact</NavLink>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h3 className="text-secondary">Contact Us</h3>
                        <div className="text-white mt-4 d-flex flex-column">
                            <a href="https://maps.app.goo.gl/gcpJ9gUpesepi4GZ7" target="_blank" rel="noopener noreferrer" className="pb-3 text-light border-bottom border-primary">
                                <i className="fas fa-map-marker-alt text-secondary me-2"></i>
                                Design center: 708 HareKrishna Complex, Opposite Kothawala Flats, Paldi, Ahmedabad, Gujarat, India 380006
                            </a>
                            <a href="https://maps.app.goo.gl/dDQ4EJ1ib9A5fbpVA" target="_blank" rel="noopener noreferrer" className="py-3 text-light border-bottom border-primary">
                                <i className="fas fa-map-marker-alt text-secondary me-2"></i>
                                Development center: A 805, Unicus Shyamal, Shyamal Cross Rd, Ahmedabad, Gujarat 380015, India
                            </a>
                            <a href="https://maps.app.goo.gl/nKPvXrR13xoqvWED8" target="_blank" rel="noopener noreferrer" className="py-3 text-light border-bottom border-primary">
                                <i className="fas fa-map-marker-alt text-secondary me-2"></i>
                                Manufacturing Center: B-11/2, 1st Floor, Electronics Estate, GIDC, Sector-25, Gandhinagar, Gujarat, India-382024
                            </a>
                            <a href="tel:+918320116640" className="py-3 text-light border-bottom border-primary">
                                <i className="fas fa-phone-alt text-secondary me-2"></i>
                                +91 83201 16640
                            </a>
                            <a href="mailto:sales@iotfied.com" className="py-3 text-light border-bottom border-primary">
                                <i className="fas fa-envelope text-secondary me-2"></i>
                                sales@iotfied.com
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="text-light mt-5 mb-4" />
                <div className="row">
                    <div className="col-md-6 text-center text-md-start">
                        <span className="text-light"><a href="https://iotfied.com/" className="text-secondary"><i className="fas fa-copyright text-secondary me-2"></i>iotfied.com</a></span>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <span className="text-light">Designed By<a href="https://www.ikartsolutions.com/" className="text-secondary"> iKart Solutions</a></span>
                    </div>
                </div>
            </div>
        </div>
    );
}
