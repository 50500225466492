import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPrint,
    faCashRegister,
    faCogs,
    faBarcode,
    faFingerprint,
    faIdCard,
    faLock,
    faCity,
    faHeartbeat,
    faTruck,
    faGavel,
    faUtensils
} from '@fortawesome/free-solid-svg-icons';
import logoimg from '../assets/logo Samples/v3/f2@2x.png';

const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = styles.menuItemHover.backgroundColor;
    e.target.style.color = styles.menuItemHover.color;
};

const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = '';
    e.target.style.color = styles.menuItem.color;
};

export default function NavBar() {
    const navigate = useNavigate();
    const [servicesDropdownHovered, setServicesDropdownHovered] = useState(false);
    const [productsDropdownHovered, setProductsDropdownHovered] = useState(false);

    const handleServicesNavigation = (path, state) => {
        navigate(path, { state });
        setServicesDropdownHovered(false);
    };

    const handleProductsNavigation = (path, state) => {
        navigate(path, { state });
        setProductsDropdownHovered(false);
    };

    return (
        <div className="container-fluid bg-primary">
            <div className="container">
                <nav className="navbar navbar-dark navbar-expand-lg py-0">
                    <Link to="/" className="navbar-brand">
                        <img src={logoimg} className="img-fluid w-100 rounded" style={styles.logoimg} alt="logoimg" />
                        {/* <h1 className="text-white fw-bold d-block">IoT<span className="text-secondary">fied</span></h1> */}
                    </Link>
                    <button
                        type="button"
                        className="navbar-toggler me-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
                        <div className="navbar-nav ms-auto mx-xl-auto p-0">
                            <NavLink to="/" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active text-secondary" : "")}>
                                Home
                            </NavLink>
                            <NavLink to="/about" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
                                About
                            </NavLink>
                            <div
                                className="nav-item dropdown"
                                onMouseEnter={() => setProductsDropdownHovered(true)}
                                onMouseLeave={() => setProductsDropdownHovered(false)}
                            >
                                <NavLink
                                    to="/"
                                    className={({ isActive }) => "nav-link dropdown-toggle" + (isActive ? "" : " active")}
                                    id="navbarDropdownProducts"
                                    role="button"
                                    aria-expanded={productsDropdownHovered ? "true" : "false"}
                                >
                                    Products
                                </NavLink>
                                <div
                                    className={"dropdown-menu" + (productsDropdownHovered ? " show" : "")}
                                    style={{ ...styles.productsDropdownMenu, display: productsDropdownHovered ? 'block' : 'none' }}
                                >
                                    <ul style={styles.ul}>
                                        <li
                                            style={styles.menuItem}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleProductsNavigation("/products", { id: 1, screen: 'Printers' })}
                                        >
                                            <FontAwesomeIcon icon={faPrint} style={styles.iconBeforeText} />
                                            Printers
                                        </li>
                                        <li
                                            style={styles.menuItem}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleProductsNavigation("/products", { id: 2, screen: 'Point Of Sales Devices' })}
                                        >
                                            <FontAwesomeIcon icon={faCashRegister} style={styles.iconBeforeText} />
                                            Point Of Sales Devices
                                        </li>
                                        <li
                                            style={styles.menuItem}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleProductsNavigation("/products", { id: 3, screen: 'Cash Drawers' })}
                                        >
                                            <FontAwesomeIcon icon={faCogs} style={styles.iconBeforeText} />
                                            Cash Drawers
                                        </li>
                                        <li
                                            style={styles.menuItem}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleProductsNavigation("/products", { id: 4, screen: 'Scanners' })}
                                        >
                                            <FontAwesomeIcon icon={faBarcode} style={styles.iconBeforeText} />
                                            Scanners
                                        </li>
                                        <li
                                            style={styles.menuItem}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleProductsNavigation("/products", { id: 5, screen: 'Time & Face Attendance Access Control Bio Metrics' })}
                                        >
                                            <FontAwesomeIcon icon={faFingerprint} style={styles.iconBeforeText} />
                                            Time & Face Attendance Access Control Bio Metrics
                                        </li>
                                        <li
                                            style={styles.menuItem}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleProductsNavigation("/products", { id: 6, screen: 'Card Readers' })}
                                        >
                                            <FontAwesomeIcon icon={faIdCard} style={styles.iconBeforeText} />
                                            Card Readers
                                        </li>
                                        <li
                                            style={styles.menuItem}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleProductsNavigation("/products", { id: 7, screen: 'Door Locks with Biometrics' })}
                                        >
                                            <FontAwesomeIcon icon={faLock} style={styles.iconBeforeText} />
                                            Door Locks with Biometrics
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div
                                className={({ isActive }) => "nav-item dropdown" + (isActive ? "" : "active")}
                                onMouseEnter={() => setServicesDropdownHovered(true)}
                                onMouseLeave={() => setServicesDropdownHovered(false)}
                            >
                                <NavLink
                                    to="#"
                                    className={({ isActive }) => "nav-link dropdown-toggle" + (isActive ? "" : "active")}
                                    id="navbarDropdown"
                                    role="button"
                                    aria-expanded={servicesDropdownHovered ? "true" : "false"}
                                >
                                    Services
                                </NavLink>
                                <div className={"dropdown-menu" + (servicesDropdownHovered ? " show" : "")} style={{ ...styles.servicesDropdownMenu, display: servicesDropdownHovered ? 'block' : 'none' }}>
                                    <div className="row g-5" style={styles.blocks}>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faCity} style={styles.iconBeforeText} />Smart cities</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleServicesNavigation("/template", { id: 2, screen: 'POS' })}>Parking</li>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleServicesNavigation("/template", { id: 4, screen: 'EMSB' })}>Spot utility billing</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faHeartbeat} style={styles.iconBeforeText} />Healthcare</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleServicesNavigation("/template", { id: 5, screen: 'RFID' })}>Patient tracking</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faTruck} style={styles.iconBeforeText} />Transportation</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleServicesNavigation("/template", { id: 1, screen: 'ITMS' })}>ITMS</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row g-5" style={styles.blocks}>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faGavel} style={styles.iconBeforeText} />Govt. Services</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleServicesNavigation("/template", { id: 6, screen: 'Traffic Ticketing Solution' })}>Traffic Ticketing Solution</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faUtensils} style={styles.iconBeforeText} />HORECA</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleServicesNavigation("/template", { id: 3, screen: 'ScanNDo' })}>ScanNDo</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NavLink to="/blog" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
                                Our Blog
                            </NavLink>
                            <NavLink to="/contact" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
                                Contact
                            </NavLink>
                        </div>
                    </div>
                    <div className="d-none d-xl-flex flex-shrink-0">
                        <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                            <Link to="#" className="position-relative animated tada infinite">
                                <i className="fa fa-phone-alt text-white fa-2x"></i>
                                <div className="position-absolute" style={{ top: '-7px', left: '20px' }}>
                                    <span><i className="fa fa-comment-dots text-secondary"></i></span>
                                </div>
                            </Link>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="text-white-50">Have any questions?</span>
                            <span className="text-secondary">Call: + 918320116640</span>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

const styles = {
    logoimg: {
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '55px',
    },
    servicesDropdownMenu: {
        position: 'absolute',
        zIndex: 1000,
        minWidth: '45rem',
        minheight: '40rem',
        padding: '1rem',
        fontSize: '1rem',
        color: '#333',
        textAlign: 'left',
        border: 'none',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
    },
    productsDropdownMenu: {
        position: 'absolute',
        zIndex: 1000,
        minWidth: '15rem',
        minheight: '10rem',
        padding: '0.5rem',
        fontSize: '1rem',
        textAlign: 'left',
        border: 'none',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
    },
    blocks: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
    },
    dropdownMenuTitles: {
        color: '#f55b14',
        fontSize: '19px',
        fontWeight: 'bold',
        borderBottom: '2px solid #f55b14',
    },
    iconBeforeText: {
        marginRight: '10px',
    },
    menuItem: {
        padding: '5px 10px',
        textDecoration: 'none',
        color: '#333',
        display: 'block',
        transition: 'background-color 0.3s ease, color 0.3s ease, transform 0.2s ease',
        borderBottom: '1px solid rgb(240, 240, 240)',
        margin: '3px 0',
        fontSize: '1rem',
        fontWeight: '500',
    },
    menuItemHover: {
        backgroundColor: '#f55b14',
        color: '#fff',
        transform: 'scale(1.02)',
    },
    ul: {
        paddingLeft: '0',
        listStyle: 'none',
    },
    '@media (max-width: 768px)': {
        servicesDropdownMenu: {
            minWidth: '30rem',
            fontSize: '0.9rem',
        },
        productsDropdownMenu: {
            minWidth: '30rem',
            fontSize: '0.9rem',
        },
    },
    '@media (max-width: 576px)': {
        servicesDropdownMenu: {
            minWidth: '20rem',
            fontSize: '0.8rem',
        },
        productsDropdownMenu: {
            minWidth: '20rem',
            fontSize: '0.8rem',
        },
    },
};
